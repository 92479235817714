<template>
	<div class="HomeMobile" @click="setViewer()">

		<div class="HomeMobile--infos-container" :class="whatBlur">
<!-- 			<div class="HomeMobile--infos-container--content">
				<div class="HomeMobile--infos-container--content--description">
          <p>Designer et devéloppeur travaillant de manière fluide entre le physique et le numérique.</p>
          <p>Pour toute demande, n’hésitez pas à nous contacter par mail.</p>
          <p>Portefolio et CV sur demande.</p>
				</div>
				<div class="HomeMobile--infos-container--content--contact">
          <p><span>Mail</span>, <span>Instagram</span>
          </p>
				</div>
			</div> -->

			<infoMain :context="'mobile'"></infoMain>

		</div>


		<transition name="SliderMobileToggle" mode="out-in">
		<div class="HomeMobile--slider-container" v-if="viewerDisplay">
			<SliderMobile :content="page.mobile"></SliderMobile>
		</div>
		</transition>

	</div>
</template>

<script>
import clientConfigs from '@/mixins/clientConfigs.js'
import SliderMobile from '@/components/mobile/SliderMobile.vue'
import InfoMain from '@/components/InfoMain2.vue'

export default {
  name: 'HomeMobile',
  mixins: [clientConfigs],
	components: {
		SliderMobile,
		InfoMain
	},
  data: function(){
    return {
      state : this.$store.state,

      viewerDisplay: false
    }
  },
  watch: {},
	computed: {
		page: function () { return this.$store.state},

		whatBlur: function(){
			if (this.viewerDisplay == true){
				return 'isBlur'
			}else {
				return ''
			}
		}
	},
	methods: {

		setViewer: function(){
			this.viewerDisplay = !this.viewerDisplay
		}

	},

	created(){
		// console.log(this.page.mobile)
	},
	mounted(){
	}
}
</script>

<style lang="scss">


.SliderMobileToggle-enter{
  transition: transform .3s ease;
  transform: translate(0, 100%);
}
.SliderMobileToggle-enter-active {
  transition: transform .3s ease;
}
.SliderMobileToggle-enter-to{
  transition: transform .3s ease;
  transform: translate(0, 0%);
}

.SliderMobileToggle-leave{
  transition: transform .3s ease;
  // top: 0vh;
  // transform: translate(0, 0%);
  opacity: 1;
}


.SliderMobileToggle-leave-active{
  transition: transform .3s ease;
}

.SliderMobileToggle-leave-to{
  transition: transform .3s ease;
  // transform: translate(0, 100%);
  opacity: 0;
}

.HomeMobile{
	position: absolute;
	height: 100%;
	width: 100%;

	cursor: pointer;

	&--slider-container{
		height: 100%;
		width: 100%;

		// background-color: red;

		position: fixed;
		overflow: scroll;
	}

	&--infos-container{
		position: fixed;
		width: 100%;
		height: 100%;
		background-color: rgba(220,220,220, 0.5);

		overflow: scroll;
		transition: all .3s ease;
		&.isBlur{
			filter: blur(5px);
		}

		// &--content{
		// 	line-height: 1.05;

		// 	font-size: 5.5vh;

		// 	font-size: min(max(30px, 5vh), 62px);

		// 	// font-size: min(max(2vh, 4vh), var(--info-font-main));
  //     // font-size: min(max(20px, 10vw, 5vh));

		// 	&--description{
		// 		position: absolute;
		// 		width: calc(100% - ( 2 * var(--home-esp-bloc-big)));
		// 		top: 0;
		// 		left: 0;
		// 		padding: var(--home-esp-bloc-big);

		// 		& p {
		// 			margin-top: var(--home-esp-bloc-big);

		// 			overflow-wrap: anywhere;
		// 			@supports ( hyphens: auto ) {
		// 				hyphens: auto;
		// 				-webkit-hyphens: auto;
		// 				-moz-hyphens: auto;
		// 				-ms-hyphens: auto;
		// 			}
		// 		}

		// 		& p:first-child{
		// 			margin-top: 0vw;
		// 		}
		// 	}

		// 	&--contact{
		// 		position: absolute;
		// 		width: calc(100% - ( 2 * var(--home-esp-bloc-big)));
		// 		bottom: 0;
		// 		left: 0;
		// 		padding: var(--home-esp-bloc-big);

		// 		padding-top: var(--info-font-main);

		// 		// font-size: var(--home-font-technical);
		// 		// font-size: min(max(1px, 4.4vh), var(--home-font-technical));

		// 		// font-size: 3.8vh;

		// 		font-size: min(max(20px, 4.5vh), 46px);

		// 		& span{
		// 			text-decoration: underline;
		// 		}

		// 		& p {
		// 			margin-top: var(--home-esp-bloc-big);
		// 		}

		// 		& p:first-child{
		// 			margin-top: 0vw;
		// 		}
		// 	}
		// }
	}

}

</style>

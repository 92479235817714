<template>

  <div class="SliderMobile">


    <SliderMobileSlide
    v-for="(image, index) in content"
    :key="index"
    :content="image">
    </SliderMobileSlide>

  </div>

</template>

<script>
import SliderMobileSlide from '@/components/mobile/SliderMobileSlide.vue'

export default {
  name: 'SliderMobile',
  props: ['content', 'context'],
  data: function(){
    return {
    }
  },
  components: {
    SliderMobileSlide
  },
  computed:{},
  methods: {

    shuffleArray: function(array){
      for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
      }
    
    }

  },
  created(){
    this.shuffleArray(this.content)
  }
}
</script>

<style lang="scss">
@import '@/css/variables';

.SliderMobile{
  width: 100%;
  // height: 90vh;
  // height: 100%;
  // background-color: grey;

  padding-top: 75vh;

}

</style>
<template>

  <div class="SliderMobileSlide"
  :style="{top: randomTop + 'vh', 'margin-bottom': -1 * randomTop + 'vh'}"
  >

    <div class="SliderMobileSlide--container"
    :style="{width: 'calc(' + whatWidth() + '% - 8vw)', 'padding-left': whatMarginLeft() + 'vw' }"
    >
    <img class="SliderMobileSlide--container--img" :src="this.content.url">

    </div>

  </div>

</template>

<script>

export default {
  name: 'SliderMobileSlide',
  props: ['content', 'context'],
  data: function(){
    return {
      randomTop: '',
      randomLeft: '',
      randomWidth: '',
      randomFlipfLap: ''
    }
  },
  components: {},
  computed:{
  },
  methods: {

    whatWidth: function(){

      let returnWidth

      if (this.randomWidth == 1){
        returnWidth = 100
      }
      if (this.randomWidth == 2){
        returnWidth = 96
      }
      if (this.randomWidth == 3){
        returnWidth = 92
      }
      if (this.randomWidth == 4){
        returnWidth = 88

      } 

      return returnWidth

    },
    whatMarginLeft: function(){

      let returnMarginLeft

      if (this.randomWidth == 1){

        if (this.randomFlipfLap == '0'){

          returnMarginLeft = 4
        }else {
          returnMarginLeft = 4
        }
        
      }
      if (this.randomWidth == 2){

        if (this.randomFlipfLap == '0'){
          returnMarginLeft = 4
        }else {
          returnMarginLeft = 8
        }
      }
      if (this.randomWidth == 3){

        if (this.randomFlipfLap == '0'){
          returnMarginLeft = 4
        }else {
          returnMarginLeft = 12
        }
      }
      if (this.randomWidth == 4){

        if (this.randomFlipfLap == '0'){
          returnMarginLeft = 4
        }else {
          returnMarginLeft = 16
        }
      } 

      return returnMarginLeft
    },
    whatTopPosition: function(){

    },
    whatMarginBottom: function(){

    },


    setRandomData: function(){

      this.randomTop = this.$tools.getRandom2(5, 45)
      this.randomLeft = this.$tools.getRandom(12)
      this.randomWidth = this.$tools.getRandom2(1, 4)
      this.randomFlipfLap = this.$tools.getRandom2(0, 1)
    }

  },
  created(){

    // console.log(this.content)

    this.setRandomData()
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.SliderMobileSlide{
  height: 100vh;
  width: 100%;

  position: sticky;

  top: 30vh;
  margin-bottom: -30vh;

  // backdrop-filter: blur(2px);
  // webkit-backdrop-filter: blur(10px);
  // background-color: rgba(255,255,255,0.1);

  // top: 20vh;
  // margin-bottom: -20vh;

  // padding-bottom: 58vh;
  // background-color: grey;

  &--container{

    width: 100%;
    height: 100%;

    max-height: 50vh;

    padding-left: 4vw;
    padding-right: 4vw;
    width: calc(100% - 8vw);

    &--img{
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

  }
}

</style>